import React from 'react'
import { HomePage } from './containers/HomePage'
import "./App.css";
import "@fontsource/montserrat"; // font import

export const App = () => {
  return (
    <div className='app-container'><HomePage /></div>
  )
}
